import dynamic from "next/dynamic";

const ContentBlock = dynamic(() => import("./ContentBlock"));
const HeroBanner = dynamic(() => import("./HeroBanner"));
const TabContent = dynamic(() => import("./TabContent"));
const BlockLinks = dynamic(() => import("./BlockLinks"));
const AccordionBlock = dynamic(() => import("./AccordionBlock"));
const BenefitFeed = dynamic(() => import("./BenefitFeed"));
const PlanDisplay = dynamic(() => import("./PlanDisplay"));
const Gform = dynamic(() => import("./Gform"));
const PostFeed = dynamic(() => import("./PostFeed"));

const AcfBlocks = {
  ContentBlock,
  HeroBanner,
  TabContent,
  BlockLinks,
  AccordionBlock,
  BenefitFeed,
  PlanDisplay,
  Gform,
  PostFeed,
};

export default AcfBlocks;
