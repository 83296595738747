import AcfBlocks from "../../acf_blocks";

const AcfBlockGenerator = ({ component, componentProps }) => {
  if (component in AcfBlocks) {
    const El = AcfBlocks[component];
    const props =
      typeof componentProps === "object"
        ? componentProps
        : JSON.parse(componentProps);
    return <El {...props} />;
  }

  return <></>;
};

export default AcfBlockGenerator;
