import { useEffect, useState } from "react";
import useSite from "../../hooks/use-site";
import {
  convertAcfBlockName,
  searchObjectRecursively,
} from "../../support/functions";
import AcfBlockGenerator from "../AcfBlockGenerator";

const AcfBlockLookup = ({ blockId }) => {
  const { acfData } = useSite();

  const [blockData, setBlockData] = useState(false);
  const [blockName, setBlockName] = useState(false);

  useEffect(() => {
    let propsBlockData = false;
    let propsBlockName = false;

    // Check if Form Data is on top line
    const topLevel = acfData.filter(
      ({ blockId: propBlockId }) => blockId === propBlockId
    );

    //
    if (topLevel.length) {
      propsBlockData = topLevel[0].acfFieldValues;
      propsBlockName = topLevel[0].name.split("/").pop();
    } else {
      acfData.map(({ innerBlocks }) => {
        const searchBlock = searchObjectRecursively(
          innerBlocks,
          "blockId",
          blockId,
          "innerBlocks"
        );

        if (searchBlock.length) {
          propsBlockData = searchBlock[0].acfFieldValues;
          propsBlockName = searchBlock[0].name.split("/").pop();
        }
      });
    }

    setBlockData(propsBlockData);
    if (propsBlockName) {
      setBlockName(convertAcfBlockName(propsBlockName));
    }
  }, [blockId]);

  return (
    <>
      {blockName && blockData && (
        <AcfBlockGenerator component={blockName} componentProps={blockData} />
      )}
    </>
  );
};

export default AcfBlockLookup;
